import React from "react"
import PropTypes from "prop-types"
import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
} from "react-share"

import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon,
} from "react-share"

const Share = ({ socialConfig }) => (
  <div className="post-social">
    <FacebookShareButton
      url={socialConfig.config.url}
      quote={socialConfig.config.title}
      hashtag={""}
    >
      <FacebookIcon size={32} round={true} />
    </FacebookShareButton>
    &ensp;
    <TwitterShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
      via={socialConfig.config.twitter}
    >
      <TwitterIcon size={32} round="true" />
    </TwitterShareButton>
    &ensp;
    <WhatsappShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
    >
      <WhatsappIcon size={32} round={true} />
    </WhatsappShareButton>
    &ensp;
    <RedditShareButton
      url={socialConfig.config.url}
      title={socialConfig.config.title}
    >
      <span className="icon">
        <RedditIcon size={32} round={true} />
      </span>
    </RedditShareButton>
    &ensp;
    <EmailShareButton
      url={socialConfig.config.url}
      subject={socialConfig.config.title}
      body={socialConfig.config.url}
    >
      <EmailIcon size={32} round={true} />
    </EmailShareButton>
  </div>
)

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitter: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
Share.defaultProps = {
  tags: [],
}

export default Share
